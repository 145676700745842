var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Card", { staticClass: "mt-6" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-between" },
          [
            _c(
              "div",
              { staticClass: "summary-grid items-center" },
              [
                _vm.onboardingSummary.percentCompletion === 0
                  ? _c(
                      "div",
                      { staticClass: "summary-circular" },
                      [
                        _c("svg-icon", {
                          staticClass: "inline-block",
                          attrs: {
                            color: "#212121",
                            size: "20",
                            type: "mdi",
                            path: _vm.svgPath.circle,
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "vue-ellipse-progress",
                      {
                        attrs: {
                          progress: _vm.onboardingSummary.percentCompletion,
                          size: _vm.progressBar.size,
                          emptyColor: "#D4CCFF",
                          color: "#6956C8",
                          thickness: _vm.progressBar.thickness,
                          "empty-thickness": _vm.progressBar.emptyThickness,
                        },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "ellipse-text text-brandPurple text-sm font-bold",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.onboardingSummary.percentCompletion) +
                                "%"
                            ),
                          ]
                        ),
                      ]
                    ),
                _c("div", { staticClass: "ml-6" }, [
                  _c("p", { staticClass: "text-dark font-semibold" }, [
                    _vm._v(_vm._s(_vm.onboardingSummary.title)),
                  ]),
                  _c(
                    "p",
                    { staticClass: "text-light-grey-2 text-xs break-words" },
                    [_vm._v(_vm._s(_vm.onboardingSummary.description))]
                  ),
                ]),
              ],
              1
            ),
            _c("SecondaryButton", {
              staticClass: "ml-4",
              attrs: {
                text: "View",
                color: "white",
                background: "#6956C8",
                p: "px-6 py-3",
              },
              on: {
                click: function ($event) {
                  return _vm.handleShowReminder(true)
                },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }