<template>
  <div>
    <Card class="mt-6">
      <div class="flex items-center justify-between">
        <div class="summary-grid items-center">
          <div v-if="onboardingSummary.percentCompletion === 0" class="summary-circular">
            <svg-icon class="inline-block" color="#212121" size="20" type="mdi" :path="svgPath.circle"></svg-icon>
          </div>
          <vue-ellipse-progress v-else :progress="onboardingSummary.percentCompletion" :size="progressBar.size"
            emptyColor="#D4CCFF" color="#6956C8" :thickness="progressBar.thickness"
            :empty-thickness="progressBar.emptyThickness">
            <p class="ellipse-text text-brandPurple text-sm font-bold">{{ onboardingSummary.percentCompletion }}%</p>
          </vue-ellipse-progress>

          <div class="ml-6">
            <p class="text-dark font-semibold">{{ onboardingSummary.title }}</p>
            <p class="text-light-grey-2 text-xs break-words">{{ onboardingSummary.description }}</p>
          </div>
        </div>
        <SecondaryButton text="View" color="white" background="#6956C8" p="px-6 py-3" class="ml-4"
          @click="handleShowReminder(true)" />
      </div>
    </Card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import VueEllipseProgress from "vue-ellipse-progress";
import { Button, SecondaryButton } from "@/UI/Button";
Vue.use(VueEllipseProgress);
import { mdiPlusCircle } from "@mdi/js";
export default {
  components: {
    Button,
    SecondaryButton,
  },
  data: () => ({
    progressBar: {
      size: 60,
      progress: 50,
      emptyThickness: 4,
      thickness: 5,
    },
    svgPath: {
      circle: mdiPlusCircle,
    },
  }),
  computed: {
    ...mapState({
      onboardingSummary: (state) => state?.dashboard?.dashboardData.onboardingSummary,
    }),
  },
  methods: {
    ...mapActions("dashboard", ["handleShowReminder"]),
  },
};
</script>
<style scoped>
.summary-circular {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: #ccffff;
}

.summary-grid {
  display: grid;
  grid-template-columns: 45px 1fr;
}
</style>
